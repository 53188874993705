<template>
	<div class="main">
		<!-- <div class="location">
			<div class="cur-location">
				<span style="line-height: 1">您所在的位置：</span>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>小程序管理</el-breadcrumb-item>
				<el-breadcrumb-item>小程序详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->
		<div class="content">
			<el-tabs v-model="activeName" @tab-click="tabsClick" class="commonTab">
				<el-tab-pane label="小程序" name="first">
					<div class="modular">
						<div class="modular_title">
							<div>小程序配置</div>
						</div>
						<div class="modular_row">
							<div>
								<span>小程序code</span>
								<span>{{ info.code }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>小程序名称</span>
								<span>{{ info.name }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>小程序密钥</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openKeyVisible(1)">配置</el-button>
							</div>
						</div>
					</div>
					<div class="modular">
						<div class="modular_title">
							<div>小程序消息模板</div>
							<div>
								<svgIcon @click="openTemplate('XA')" iconClass="add1" cursor="pointer" />
							</div>
						</div>
						<div class="table">
							<el-table border stripe size="mini" height="230px" :data="XTableData">
								<el-table-column
									show-overflow-tooltip
									prop="templateCode"
									:label="$t('AppletDetails.templateCode')"
								></el-table-column>
								<el-table-column
									show-overflow-tooltip
									prop="templateName"
									:label="$t('AppletDetails.templateName')"
								></el-table-column>
								<el-table-column
									show-overflow-tooltip
									prop="templateId"
									:label="$t('AppletDetails.templateId')"
								></el-table-column>
								<!-- <el-table-column
									show-overflow-tooltip
									prop="jumpUrl"
									:label="$t('AppletDetails.jumpUrl')"
								></el-table-column> -->
								<el-table-column
									show-overflow-tooltip
									prop="isEnable"
									:label="$t('AppletDetails.isEnable')"
								>
									<template v-slot="{ row }">
										{{ row.isEnable ? '启用' : '停用' }}
									</template>
								</el-table-column>
								<el-table-column
									show-overflow-tooltip
									prop="updateTime"
									:label="$t('AppletDetails.updateTime')"
								>
									<template v-slot="{ row }">
										{{ row.updateTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
									</template>
								</el-table-column>

								<el-table-column :label="$t('common.operation')" width="140">
									<template v-slot="{ row }">
										<el-button size="mini" type="text" @click="openTemplate('XE', row)">
											{{ $t('common.edit') }}
										</el-button>
										<el-dropdown trigger="click" style="margin-left: 10px" @command="Xmenu">
											<el-button size="mini" type="text">
												{{ $t('common.moreOperate') }}
												<i class="el-icon-arrow-down el-icon--right"></i>
											</el-button>
											<el-dropdown-menu slot="dropdown">
												<el-dropdown-item :command="{ ...row, btnType: 'isEnable' }">
													{{
														row.isEnable ? $t('common.stopUsing') : $t('common.startUsing')
													}}
												</el-dropdown-item>
												<el-dropdown-item :command="{ ...row, btnType: 'template' }">
													{{ $t('AppletDetails.template') }}
												</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<gl-page
							@size-change="XSizeChange"
							@current-change="XPageInfo"
							:current-page="XFormInline.pageNum"
							:page-size="XFormInline.pageSize"
							:total="XTotal"
						></gl-page>
					</div>
				</el-tab-pane>
				<el-tab-pane label="公众号" name="third">
					<div class="modular">
						<div class="modular_title">
							<div>公众号配置</div>
						</div>
						<div class="modular_row">
							<div>
								<span>公众号code</span>
								<span>{{ info.openCode }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>公众号名称</span>
								<span>{{ info.openName }}</span>
							</div>
						</div>
						<div class="modular_row">
							<div>
								<span>公众号密钥</span>
								<span></span>
							</div>
							<div>
								<el-button size="mini" @click="openKeyVisible(0)">配置</el-button>
							</div>
						</div>
					</div>
					<div class="modular">
						<div class="modular_title">
							<div>公众号消息模板</div>
							<div>
								<svgIcon @click="openTemplate('GA')" iconClass="add1" cursor="pointer" />
							</div>
						</div>
						<div class="table">
							<el-table border stripe size="mini" height="230px" :data="GTableData">
								<el-table-column
									show-overflow-tooltip
									prop="templateCode"
									:label="$t('AppletDetails.templateCode')"
								></el-table-column>
								<el-table-column
									show-overflow-tooltip
									prop="templateName"
									:label="$t('AppletDetails.templateName')"
								></el-table-column>
								<el-table-column
									show-overflow-tooltip
									prop="templateId"
									:label="$t('AppletDetails.templateId')"
								></el-table-column>
								<!-- <el-table-column
									show-overflow-tooltip
									prop="jumpUrl"
									:label="$t('AppletDetails.jumpUrl')"
								></el-table-column> -->
								<el-table-column
									show-overflow-tooltip
									prop="isEnable"
									:label="$t('AppletDetails.isEnable')"
								>
									<template v-slot="{ row }">
										{{ row.isEnable ? '启用' : '停用' }}
									</template>
								</el-table-column>
								<el-table-column
									show-overflow-tooltip
									prop="updateTime"
									:label="$t('AppletDetails.updateTime')"
								>
									<template v-slot="{ row }">
										{{ row.updateTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
									</template>
								</el-table-column>

								<el-table-column :label="$t('common.operation')" width="140">
									<template v-slot="{ row }">
										<el-button size="mini" type="text" @click="openTemplate('GE', row)">
											{{ $t('common.edit') }}
										</el-button>
										<el-dropdown trigger="click" style="margin-left: 10px" @command="Xmenu">
											<el-button size="mini" type="text">
												{{ $t('common.moreOperate') }}
												<i class="el-icon-arrow-down el-icon--right"></i>
											</el-button>
											<el-dropdown-menu slot="dropdown">
												<el-dropdown-item :command="{ ...row, btnType: 'isEnable' }">
													{{
														row.isEnable ? $t('common.stopUsing') : $t('common.startUsing')
													}}
												</el-dropdown-item>
												<el-dropdown-item :command="{ ...row, btnType: 'template' }">
													{{ $t('AppletDetails.template') }}
												</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<gl-page
							@size-change="GSizeChange"
							@current-change="GPageInfo"
							:current-page="GFormInline.pageNum"
							:page-size="GFormInline.pageSize"
							:total="GTotal"
						></gl-page>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<el-dialog :title="title" :visible.sync="visible" width="600" center :close-on-click-modal="false">
			<el-form ref="form" :model="form" label-width="100px" class="form" :rules="rules">
				<el-form-item :label="$t('AppletDetails.templateCode')" prop="templateCode">
					<el-input size="mini" v-model="form.templateCode"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletDetails.templateName')" prop="templateName">
					<el-input size="mini" v-model="form.templateName"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletDetails.templateId')" prop="templateId">
					<el-input size="mini" v-model="form.templateId"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletDetails.jumpUrl')" prop="jumpUrl">
					<el-input size="mini" v-model="form.jumpUrl"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletDetails.isEnable')" prop="isEnable">
					<el-switch v-model="form.isEnable"></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="visible = false">取 消</el-button>
				<el-button type="primary" @click="saveTemplate">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="消息模板内容" :visible.sync="itemVisible" width="600" center :close-on-click-modal="false">
			<div class="modular">
				<div class="modular_title">
					<div>消息模板</div>
					<div>
						<svgIcon @click="openItemTemplate" iconClass="add1" cursor="pointer" />
					</div>
				</div>
				<div class="table">
					<el-table border stripe size="mini" height="205px" :data="XItemTableData">
						<el-table-column
							show-overflow-tooltip
							prop="wxKey"
							:label="$t('AppletDetails.wxKey')"
						></el-table-column>
						<el-table-column
							show-overflow-tooltip
							prop="value"
							:label="$t('AppletDetails.value')"
						></el-table-column>
						<el-table-column
							show-overflow-tooltip
							prop="replaceKey"
							:label="$t('AppletDetails.replaceKey')"
						></el-table-column>

						<el-table-column :label="$t('common.operation')" width="140">
							<template v-slot="{ row }">
								<el-button size="mini" type="text" @click="delItemTemplate(row)">
									{{ $t('common.del') }}
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<gl-page
					@size-change="XItemSizeChange"
					@current-change="XItemPageInfo"
					:current-page="XItemFormInline.pageNum"
					:page-size="XItemFormInline.pageSize"
					:total="XItemTotal"
				></gl-page>
			</div>
			<!-- <span slot="footer" class="dialog-footer">
				<el-button @click="visible = false">取 消</el-button>
				<el-button type="primary" @click="saveTemplate">确 定</el-button>
			</span> -->
		</el-dialog>
		<el-dialog
			title="新增消息模板内容"
			:visible.sync="addItemVisible"
			width="600"
			center
			:close-on-click-modal="false"
		>
			<el-form ref="itemForm" :model="itemForm" label-width="100px" class="form" :rules="rules">
				<el-form-item :label="$t('AppletDetails.wxKey')" prop="wxKey">
					<el-input size="mini" v-model="itemForm.wxKey"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletDetails.value')" prop="value">
					<el-input size="mini" v-model="itemForm.value"></el-input>
				</el-form-item>
				<el-form-item :label="$t('AppletDetails.replaceKey')" prop="replaceKey">
					<el-input size="mini" v-model="itemForm.replaceKey"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addItemVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveItemTemplate">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:title="keyValue ? '配置小程序密钥' : '配置公众号密钥'"
			:visible.sync="keyVisible"
			width="600"
			center
			:close-on-click-modal="false"
		>
			<el-form ref="keyForm" :model="keyForm" label-width="100px" class="form" :rules="rules">
				<el-form-item :label="keyValue ? '小程序密钥' : '公众号密钥'" prop="keyValue">
					<el-input size="mini" v-model="keyForm.keyValue"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="keyVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveKey">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import svgIcon from '@/components/svgIcon.vue'

export default {
	components: {
		svgIcon
	},
	data() {
		return {
			XTableData: [],
			XItemTableData: [],
			XFormInline: {
				pageNum: 1,
				pageSize: 5,
				param: {
					organizationCode: '',
					templateCode: '',
					templateId: '',
					templateType: 1
				}
			},
			XItemFormInline: {
				pageNum: 1,
				pageSize: 5,
				param: 0
			},
			XTotal: 0,
			XItemTotal: 0,
			GTableData: [],
			GFormInline: {
				pageNum: 1,
				pageSize: 5,
				param: {
					organizationCode: '',
					templateCode: '',
					templateId: '',
					templateType: 0
				}
			},
			GTotal: 0,
			info: {
				code: '',
				name: '',
				openCode: '',
				openName: ''
			},
			title: '',
			visible: false,
			form: {
				id: '',
				isEnable: '',
				jumpUrl: '',
				organizationCode: '',
				templateCode: '',
				templateName: '',
				templateId: '',
				templateType: 1
			},
			rules: {
				templateCode: [{ required: true, message: '请输入模板编号', trigger: 'blur' }],
				templateName: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
				templateId: [{ required: true, message: '请输入模板id', trigger: 'blur' }],
				jumpUrl: [{ required: true, message: '请输入跳转路径', trigger: 'blur' }],
				wxKey: [{ required: true, message: '请输入微信模板key', trigger: 'blur' }],
				value: [{ required: true, message: '请输入常量值', trigger: 'blur' }]
				// replaceKey: [{ required: true, message: '请输入变量模板key', trigger: 'blur' }]
			},
			itemVisible: false,
			addItemVisible: false,
			itemForm: {
				id: '',
				organizationCode: '',
				replaceKey: '',
				templateId: '',
				value: '',
				wxKey: ''
			},
			keyVisible: false,
			keyForm: {
				keyValue: '',
				organizationId: ''
			},
			keyValue: 1,
			activeName: 'first'
		}
	},
	mounted() {
		if (this.$route.query.code) {
			this.info.code = this.$route.query.code
			this.XFormInline.param.organizationCode = this.$route.query.code
			this.GFormInline.param.organizationCode = this.$route.query.code
		}
		if (this.$route.query.name) {
			this.info.name = this.$route.query.name
		}
		if (this.$route.query.openCode) {
			this.info.openCode = this.$route.query.openCode
		}
		if (this.$route.query.openName) {
			this.info.openName = this.$route.query.openName
		}
		this.XPageInfo()
		this.GPageInfo()
	},
	methods: {
		tabsClick(e, t) {
			console.log(e, t)
			this.activeName = e._props.name
		},
		openKeyVisible(value) {
			this.keyValue = value
			this.keyVisible = true
			this.keyForm.organizationId = this.$route.query.id
			this.$nextTick(() => {
				this.$refs.keyForm.resetFields()
			})
		},
		saveKey() {
			console.log(this.keyForm)
			if (this.keyValue) {
				this.ajax.post('/admin/miniApp/manage/keyAppUpdate', this.keyForm).then(() => {
					this.keyVisible = false
				})
			} else {
				this.ajax.post('/admin/miniApp/manage/keyOpenUpdate', this.keyForm).then(() => {
					this.keyVisible = false
				})
			}
		},
		GSizeChange(i) {
			this.GFormInline.pageSize = i
			this.GPageInfo()
		},
		XSizeChange(i) {
			this.XFormInline.pageSize = i
			this.XPageInfo()
		},
		XItemSizeChange(i) {
			this.XItemFormInline.pageSize = i
			this.XItemPageInfo()
		},
		delItemTemplate(row) {
			this.$confirm('是否继续该操作').then(() => {
				this.ajax.post('/admin/msg/manage/delTemplateItem', { id: row.id }).then(() => {
					this.XItemPageInfo()
				})
			})
		},
		saveItemTemplate() {
			this.$refs.itemForm.validate(valid => {
				if (valid) {
					this.ajax.post('/admin/msg/manage/saveTemplateItem', this.itemForm).then(() => {
						this.addItemVisible = false
						this.XItemPageInfo()
					})
				}
			})
		},
		openItemTemplate(row) {
			Object.assign(this.itemForm, row)
			this.itemForm.organizationCode = this.$route.query.code
			this.itemForm.templateId = this.XItemFormInline.param
			this.addItemVisible = true
			this.$nextTick(() => {
				this.$refs.itemForm.resetFields()
			})
		},
		XItemPageInfo(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.XItemFormInline.pageNum = i
			this.ajax.post('/admin/msg/manage/itemPageInfo', this.XItemFormInline).then(res => {
				console.log(res)
				this.XItemTableData = res.list
				this.XItemTotal = res.total
			})
		},
		Xmenu(row) {
			console.log(row)
			if (row.btnType == 'isEnable') {
				this.$confirm('是否继续该操作').then(() => {
					this.ajax
						.post('/admin/msg/manage/upTemplate', {
							id: row.id,
							operator: !row.isEnable
						})
						.then(() => {
							this.$message.success(this.$t('common.success'))
							this.XPageInfo()
							this.GPageInfo()
						})
				})
			} else if (row.btnType == 'template') {
				this.itemVisible = true
				this.XItemFormInline.param = row.id
				this.XItemPageInfo()
			}
		},

		openTemplate(str, row) {
			console.log(str, row)
			this.visible = true
			if (str == 'XA') {
				this.title = '新增小程序消息模板'
				this.form = {
					id: '',
					isEnable: '',
					jumpUrl: '',
					organizationCode: '',
					templateCode: '',
					templateName: '',
					templateId: '',
					templateType: 1
				}
				this.form.organizationCode = this.$route.query.code
			} else if (str == 'XE') {
				this.title = '编辑小程序消息模板'
				Object.assign(this.form, row)
				this.form.templateType = 1
				this.form.organizationCode = this.$route.query.code
			} else if (str == 'GA') {
				this.title = '新增公众号消息模板'
				this.form = {
					id: '',
					isEnable: '',
					jumpUrl: '',
					organizationCode: '',
					templateCode: '',
					templateId: '',
					templateType: 0
				}
				this.form.organizationCode = this.$route.query.code
			} else if (str == 'GE') {
				this.title = '编辑公众号消息模板'
				Object.assign(this.form, row)
				this.form.templateType = 0
				this.form.organizationCode = this.$route.query.code
			}
		},
		saveTemplate() {
			this.$refs.form.validate(valid => {
				if (valid) {
					console.log(this.form)
					this.ajax.post('/admin/msg/manage/saveTemplate', this.form).then(() => {
						this.visible = false
						if (this.form.templateType == 1) {
							this.XPageInfo()
						} else {
							this.GPageInfo()
						}
					})
				}
			})
		},
		XPageInfo(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.XFormInline.pageNum = i
			this.ajax.post('/admin/msg/manage/pageInfo', this.XFormInline).then(res => {
				console.log(res)
				this.XTotal = res.total
				this.XTableData = res.list
			})
		},
		GPageInfo(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.GFormInline.pageNum = i
			this.ajax.post('/admin/msg/manage/pageInfo', this.GFormInline).then(res => {
				console.log(res)
				this.GTotal = res.total
				this.GTableData = res.list
			})
		}
	}
}
</script>
<style lang="scss" scoped>
@import '../assets/css/global.scss';
.content {
	width: 100%;
	height: calc(100% - 26px);
	overflow: auto;
}
.modular {
	width: 100%;
	margin-bottom: 20px;
	.modular_title {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		// color: #fff;
		@include modular_title_color(#fff);
		& div:nth-child(1) {
			font-size: 16px;
			height: 30px;
			font-weight: bold;
		}
		& div:nth-child(2) {
			margin-left: 20px;
			margin-bottom: 4px;
			@include s_font_color(#fff);
		}
	}
	.modular_row {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #625a5a;
		line-height: 50px;
		height: 50px;
		font-size: 12px;
		font-weight: bold;
		@include modular_title_color(#fff);
		& div:nth-child(1) span:nth-child(1) {
			display: inline-block;
			width: 200px;
			margin-right: 50px;
		}
	}
	.table {
		margin: 0;
	}
}
</style>
